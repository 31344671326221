<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="6">
        <h1>
          Peticiones de
          <span class="font-weight-light">Ayuda</span>
        </h1>
      </v-col>

      <v-col cols="12" md="6">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="350px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              class="text-center"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="helpRequests"
          :search="$store.state.search"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          sort-by="createdAt"
          class="mt-10"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.businessId`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  class="d-inline-flex"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.businessId)"
                >
                  {{ item.businessId }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.contactPhone`]="{ item }">
            <v-tooltip v-if="item.contactPhone" right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  class="d-inline-flex"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.contactPhone)"
                >
                  {{ item.contactPhone }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.orderShortId`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  class="d-inline-flex"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="
                    copyToClipboard(
                      item.orderShortId ? item.orderShortId : item.orderId
                    )
                  "
                >
                  {{ item.orderShortId ? item.orderShortId : item.orderId }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.type | filterType }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status === 'completed'">
              <v-chip
                :color="getColor(item.status)"
                dark
                style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)"
              >
                {{ item.status | statusFilter }}
              </v-chip>
            </div>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="getColor(item.status)"
                  dark
                  v-on="on"
                  @click="updateStatus(item)"
                  style="
                    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                  "
                >
                  {{ item.status | statusFilter }}
                </v-chip>
              </template>
              <span>
                Click para cambiar el estado a <strong>aceptado</strong>
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "helpRequests",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbarText: "",
      helpRequests: [],
      headers: [
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Id de Orden",
          value: "orderShortId",
        },
        {
          text: "Tipo",
          value: "type",
          width: "250px",
        },
        {
          text: "Nombre del comercio",
          value: "businessName",
          width: "260px",
        },
        // {
        //   text: "Comercio",
        //   value: "businessId",
        // },

        {
          text: "Teléfono de contacto",
          value: "contactPhone",
        },
        {
          text: "Descripción",
          value: "description",
          width: "400px",
        },
        {
          text: "Creada",
          value: "createdAt",
          width: "250px",
        },
      ],
      statusColors: [
        { name: "created", color: "lime darken-1" },
        { name: "completed", color: "blue" },
      ],
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY @ h:mm a");
    },
    statusFilter: function (status) {
      switch (status) {
        case "created":
          return "Creada";
        case "completed":
          return "Completada";
        default:
          return "Desconocido";
      }
    },

    filterType: function (status) {
      switch (status) {
        case "placed":
          return "Orden colocada";
        case "accepted":
          return "Orden aceptada";
        case "closeBusiness":
          return "Solicitud de cierre";
        case "history":
          return "Historial";
        case "timeOut":
          return "Tiempo de espera agotado";
        default:
          return status;
      }
    },
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  mounted() {
    this.$store.commit("setSearchTerm", "Peticiones de ayuda");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.getHelpRequest();
  },
  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
      this.getHelpRequest();
    },
    async getHelpRequest() {
      this.loading = true;
      let helpRequests = this.$binding(
        "helpRequests",
        db
          .collection("helpRequests")
          .where(
            "createdAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "createdAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          )
      );
      this.loading = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error(" Could not copy text: ", err);
        }
      );
    },
    getColor(status) {
      const result = this.statusColors.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "grey";
      }
    },
    updateStatus(item) {
      this.saving = true;
      if (confirm(`¿Estás seguro de actualizar ${item.orderId}?`)) {
        db.collection("helpRequests")
          .doc(item[".key"])
          .update({
            status: "completed",
            modifiedAt: new Date(),
            modifiedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.snackbar = true;
            this.snackbarText = `Solicitud ${item.orderId} modificada con éxito 👍`;
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = `Error al modificar solicitud ${item.orderId}, ${err}`;
          });
      }
      this.saving = false;
    },
  },
};
</script>

<style>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
